import React, { useEffect, useContext, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
// import { Router } from "@reach/router"
import Glide from "@glidejs/glide"
import { useMediaPredicate } from "react-media-hook"
import ReactMarkdown from "react-markdown"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Container } from "../../components/grid/grid"

import "../../graphql/fragments"

import { LangContext } from "../../context/langContext"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Cover } from "../../components/cover/cover"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Card } from "../../components/card/card"
import { HeadingBasic } from "../../components/heading/heading"
import { Button } from "../../components/buttons/buttons"
import {
  WpSusDetailGroup,
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
  WpImagelistFocusArea,
} from "../../components/utils/render-functions"
// import SustainabilityDetail from "../../templates/sustainability-detail"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const OurSustainabilityProgramForCommunitiesPage = ({ location }) => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "sustainability/our-sustainability-program-for-communities") {
          id
          title
          slug
          featuredImage {
            sourceUrl
            mediaItemUrl
            title
            caption
          }
          blocks {
            name
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...CoreEmbedYoutube
            ...CoreButtonBlock
            ...AcfListImageBlock
          }
          childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              title
              slug
              excerpt
              menuOrder
              featuredImage {
                sourceUrl
                mediaItemUrl
                title
                caption
              }
              translation(language: ID) {
                title
                slug
                excerpt
                featuredImage {
                  sourceUrl
                  mediaItemUrl
                  title
                  caption
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
              ...AcfGalleryBlock
              ...CoreEmbedYoutube
              ...AcfListImageBlock
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const gallery = document.querySelectorAll("#gallery")

    gallery.forEach(item => {
      const slider = new Glide(item, {
        perView: 3,
        gap: 10,
        startAt: 0,
        breakpoints: {
          992: {
            perView: 1,
          },
        },
      })

      slider.mount()
    })

    const thisElement = document.querySelector(".py-main .container")
    thisElement.classList.remove("container-md")
  }, [])

  const wordPress =
    lang === "EN"
      ? data?.wordPress?.pageBy?.blocks
      : data?.wordPress?.pageBy?.translation?.blocks ||
        data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]
  const featuredImage = data?.wordPress?.pageBy?.featuredImage?.mediaItemUrl

  const [coverItems, setCoverItems] = useState([])
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  useEffect(() => {
    wordPress && wordPress?.map(item => {
      if(item.name === "core/cover"){
        setCoverItems((prev)=>[...prev, {url: item.attributes.url}])
      }
    })
  }, [resizeScreen, wordPress])

  return (
    <Layout currentLocation={location.uri} lang="EN">
      <SEO 
        img={featuredImage}
        description={seo?.opengraphDescription}
        title={seo?.title}
        fbImg={featuredImage}
        fbTitle={seo?.opengraphTitle || seo?.title}
        fbDescription={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        url={location?.href}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            lang === "EN"
              ? data?.wordPress?.pageBy?.title
              : data?.wordPress?.pageBy?.translation?.title ||
                data?.wordPress?.pageBy?.title
          }
          /* img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          } */
          img={(resizeScreen ? coverItems[1]?.url : coverItems[0]?.url) ?? data.imgCoverMain.childImageSharp.fluid}
          alt="Best in Class Real Estate"
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

    	{wordPress &&
        wordPress.map((item, i) => {
          switch (item.name) {
            case "core/heading":
              return (
                <div key={`heading-${i}`} className="container">
                  <div className={i === 0 ? "py-main" : ""}>
                    <HeadingBasic h2={item.attributes.content} />
                  </div>
                </div>
              )
            case "core/buttons":
              if (
                item?.innerBlocks[0]?.attributes?.url &&
                item?.innerBlocks[0]?.attributes?.url.split(".").length > 1
              ) {
                return (
                  <div key={`button=${i}`} className="container">
                    <div className={i === 0 ? "py-main" : "pb-main"}>
                      <HeadingBasic>
                        <a
                          href={item?.innerBlocks[0]?.attributes?.url}
                          target="_blank"
                          className="btn btn-link fx-underline"
                          rel="noopener noreferrer"
                          key={`${item?.innerBlocks[0]?.attributes?.url}-${i}`}
                        >
                          {item?.innerBlocks[0]?.attributes?.text} &nbsp;
                          <i className="far fa-external-link"></i>
                        </a>
                      </HeadingBasic>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={`button-${i}`} className="container pt-2 pb-5 text-right">
                    <Link
                      key={`${item?.innerBlocks[0]?.attributes?.url !== null
                        ? item?.innerBlocks[0]?.attributes?.url
                        : "linkKey"}-${i}`}
                      to={item?.innerBlocks[0]?.attributes?.url !== null
                        ? item?.innerBlocks[0]?.attributes?.url
                        : "#"
                      }
                      className="btn btn-link fx-underline"
                    >
                      {item?.innerBlocks[0]?.attributes?.text !== null
                        ? item?.innerBlocks[0]?.attributes?.text
                        : lang === "EN"
                          ? "View More"
                          : "Selengkapnya"
                      }
                    </Link>
                  </div>
                )
              }
            case "core/paragraph":
              return (
                <div
                  key={`headingbasic-${i}`}
                  className={`${i === 1 ? "py-main sus-detail-paragraph" : "py-3"}`}
                >
                  <div className="container container-md">
                    <HeadingBasic text={[item.attributes.content]} className="mw-100 pl-2-point-5" />
                  </div>
                </div>
              )
            case "core/group":
              if (
                i === 1 ||
                (wordPress[i - 1] &&
                  wordPress[i - 1].name === "acf/acfgallery") ||
                i === 0
              ) {
                return (
                  <div key={`${item.name}-${i}`} className="py-5">
                    <WpSusDetailGroup data={item} />
                  </div>
                )
              } else {
                return (
                  <div key={`${item.name}-${i}`} className="pb-5">
                    <WpSusDetailGroup data={item} />
                  </div>
                )
              }
            case "acf/iconlist":
              return (
                <div
                  key={`iconlist-${i}`}
                  className={
                    i === 0 ? "container py-main" : "container pb-main"
                  }
                >
                  <WpIconlist data={item} />
                </div>
              )
            case "acf/labelvalues":
              return (
                <div key={`${item.name}-${i}`} className="pb-main">
                  <Stats data={item} />
                </div>
              )
            case "core/cover":
              if (i !== 0) {
                return (
                  <div key={`featured-${i}`} className="pb-main">
                    <CoverFeatured
                      label="Featured News"
                      img={
                        item.attributes.url ||
                        data.featured.childImageSharp.fluid
                      }
                      title={item.innerBlocks[0].attributes.content}
                      text={item.innerBlocks[1].attributes.content}
                      btnText={
                        item.innerBlocks[2].innerBlocks[0].attributes.text
                      }
                      btnLink={
                        item.innerBlocks[2].innerBlocks[0].attributes.url
                      }
                    />
                  </div>
                )
              }
              break
            case "acf/acfgallery":
              return (
                <Container
                  className={`${i === wordPress.length - 1 ? "mb-5" : ""}`}
                  key={`gallery-${i}`}
                >
                  <Carousel data={item} />
                </Container>
              )
            case "core/list":
              return (
                <Container className="container-md">
                  <div className="heading heading-basic" style={{maxWidth:"100%",textAlign:"justify"}}>
                    {item.attributes.className == "disklist" &&(
                      <ul style={{paddingLeft:"20px"}}>
                        <ReactMarkdown
                          source={item.attributes.values}
                          escapeHtml={false}
                        />
                      </ul>
                    )}
                    {item.attributes.className == "numberlist" &&(
                      <ul style={{listStyleType:"number"}}>
                        <ReactMarkdown
                          source={item.attributes.values}
                          escapeHtml={false}
                        />
                      </ul>
                    )}
                  </div>
                </Container>
              )
            case "acf/list-image":
              return (
                <Container>
                  <WpImagelistFocusArea data={item} />
                </Container>
              )
            default:
              return <div key={`default-${i}`}>{item.name}</div>
          }
          return <div key={`value-${i}`}></div>
        })}
    </Layout>
  )
}

export default OurSustainabilityProgramForCommunitiesPage
